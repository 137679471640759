import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Cart from "./components/pages/Cart";
import OrderSuccess from "./components/pages/OrderSuccess";
import OrderDetails from "./components/pages/OrderDetails";
import UserDashboard from "./components/pages/UserDashboard";
import AdminDashboard from "./components/pages/admin/AdminDashboard";
import Logout from "./components/pages/Logout";
import PartnerLogin from "./components/pages/delivery/PartnerLogin";
import { Provider } from "react-redux";
import store from "./app/store";
import PartnerOrders from "./components/pages/delivery/PartnerOrders";
import VendorLogin from "./components/pages/vendor/VendorLogin";
import VendorDashboard from "./components/pages/vendor/VendorDashboard";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}></Route>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/placeorder" element={<OrderSuccess />} />
          <Route path="/orderdetails/:id" element={<OrderDetails />} />
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/partnerlogin" element={<PartnerLogin />} />
          <Route path="/partnerOrders" element={<PartnerOrders />} />
          <Route path="/vendorlogin" element={<VendorLogin />} />
          <Route path="/vendordashboard" element={<VendorDashboard/>}/>
        </Routes>
      </BrowserRouter>
    </Provider>

);
