import React, { useEffect, useState } from 'react'
import VendorProdEdit from './VendorProdEdit';
import { db } from '../../../firebase-config';
import { collection, addDoc,query,where, getDocs } from 'firebase/firestore';
import Cookies from 'universal-cookie';

const VendorProductsList = () => {

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const[prodLoading,setProdLoading] = useState(true)
    const addProduct = () => {
        setLoading(true)
        let cookies = new Cookies();
        let vendor = cookies.get('vendor')
        const vendorProdRef = collection(db, 'vendorproducts');

        addDoc(vendorProdRef, { name: '', price: 0, unit: 'KG', vendor: vendor })
            .then((res) => {
                setLoading(false);
                getProducts()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => { 
        const getProducts = () => { 
            let cookies = new Cookies();
            let vendor = cookies.get('vendor')
            const vendorProdRef = query(collection(db, 'vendorproducts'), where('vendor', '==', vendor));
            getDocs(vendorProdRef)
                .then((res) => {
                    const list = res.docs.map((doc) => ({ id: doc.id, doc: doc.data() }));
                    setProducts(list);
                    setProdLoading(false);
                   
                })
                .catch((err) => { 
                    setProdLoading(false)
                    console.log(err)
                })
        }
        getProducts();

    }, [])
    
    const getProducts = () => { 
        let cookies = new Cookies();
        let vendor = cookies.get('vendor')
        const vendorProdRef = query(collection(db, 'vendorproducts'), where('vendor', '==', vendor));
        getDocs(vendorProdRef)
            .then((res) => {
                const list = res.docs.map((doc) => ({ id: doc.id, doc: doc.data() }));
                setProducts(list);
                setProdLoading(false);
               
            })
            .catch((err) => { 
                setProdLoading(false)
                console.log(err)
            })
    }
   

    return (
        <div>
            <div className="flex justify-between items-center bg-gray-50 p-4">
                <h3 className="text-xl font-semibold">Products list</h3>
                <button
                    className="bg-indigo-700 text-white px-2 py-1 rounded hover:bg-indigo-900"
                    onClick={addProduct}
                    disabled={loading}
                >

                    {loading ? "Please wait..." : '+ Add Product'}
                </button>
            </div>
            <div className='p-4'>
                <table className='w-full'>
                    <thead>
                        <tr>
                            <th className='text-left p-1'>Name</th>
                            <th className='text-left p-1'>Unit</th>
                            <th className='text-left p-1'>Wholesale price</th>
                          
                            <th className='text-center p-1'>Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            products.map((prod) => { 
                                return (
                                    <VendorProdEdit data={prod} key={prod.id} />
                                )
                            })
                        }
                        
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default VendorProductsList