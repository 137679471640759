import React, { useState,useEffect} from "react";
import { useDispatch } from "react-redux";
import { updateCart } from "../features/cart";
import AddItem from "./common/AddItem";

const Product = ({ data }) => {
  const [quantity, setQuantity] = useState(0);
  const [minunit, set_min_unit] = useState(1);
  const [added, setAdded] = useState(false);
 
  const cartData = { ...data };
  
  
  const dispatch = useDispatch();
  const cartDataHandler = (quant,minunit) => {
    
    setQuantity(quant);
    set_min_unit(minunit)
   
  
  }
  useEffect(() => { 

    setAdded(true);
    setTimeout(() => {
     
      cartData.quantity = quantity;
      cartData.minUnit = minunit;
     
      dispatch(updateCart(cartData));
     },100)
  })
  // const calcPrice = (commission,price) => { 
  //   let commValue = Number(price) * (commission / 100);
  //   return (Number(price) + commValue).toFixed(2)
  // }
  return (

    

    // <div className="border rounded-lg hover:shadow-md relative">
    //   {quantity > 0 && added ? (
    //     <span className="p-1 bg-green-400 absolute rounded right-2 text-sm top-2  ">
    //       Added{" "}
    //     </span>
    //   ) : null}
    //   <div
    //     className="w-full h-56"
    //     style={{
    //       backgroundImage: `url(${data.doc.image})`,
    //       backgroundSize: "60%",
    //       backgroundPosition: "center",
    //       backgroundRepeat:'no-repeat'
    //     }}
    //   ></div>
    //   <div className="border-t px-6 py-4">
    //     <h3 className=" text-lg font-semibold">{data.doc.title}</h3>
    //     <p className="text-sm text-gray-600 h-12 mb-2">
    //       {data.doc.description}
    //     </p>
    //     <p className=" font-semibold text-xl mb-6">
    //       INR {data.doc.retailPrice} <small> for {data.doc.minQuantity}{data.doc.unit}</small>
    //     </p>
    //     <div className=" mt-4  w-full mb-2">
    //       <AddItem cartDataHandler={cartDataHandler} item={data.doc} />
        
    //     </div>
    //   </div>
    // </div>

    <div className="max-w-xs overflow-hidden bg-white rounded-lg shadow-lg ">
    <div className="px-4 py-3">
        <h1 className="text-2xl font-bold text-gray-800 uppercase relative">{data.doc.title}
          
      {quantity > 0 && added ? (
       <span className="p-1 bg-green-400 absolute rounded right-1 text-xs top-1 ">
          Added{" "}
        </span>
     ) : null}
        </h1>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">{data.doc.description}</p>
      </div>
      <p className="border-b"></p>

      <img className="object-scale-down w-full h-48 mt-2" src={data.doc.image} alt="NIKE AIR" />

    <div className="flex items-center justify-between px-4 py-2 bg-slate-500">
        <h1 className="text-lg font-bold text-white">INR {data.doc.retailPrice} <small> for {data.doc.minQuantity}{data.doc.unit}</small></h1>
        {/* <button className="px-2 py-3 text-xs font-semibold text-gray-900 uppercase transition-colors duration-300 transform bg-white rounded hover:bg-gray-200 focus:bg-gray-400 focus:outline-none">Add to cart</button> */}
        
      </div>
      <AddItem cartDataHandler={cartDataHandler} item={data.doc} />
</div>
  );
};

export default Product;
