import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase-config';
import { collection, getDocs } from 'firebase/firestore';
const DelPartnerList = (props) => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    useEffect(() => {

        const getPartners = () => {
            let delPartnerRef = collection(db, 'delpartners');

            getDocs(delPartnerRef).then((res) => {
                setLoading(false);
                const list = res.docs.map((doc) => ({
                    doc: doc.data(),
                    id: doc.id,
                }));
                
                setList(list)

            }).catch((err) => { console.log(err); setLoading(false) })

        }
        getPartners()

    }, []);

    if (loading) {
        return (<p>Please wait...</p>)
    }
    return (
        <table className="w-full text-sm  text-gray-600 rounded mt-4 border">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 rounded-lg">
                <tr>
                    <th className='text-left p-3 '>Name</th>
                    <th className='text-left p-3 ' colSpan={2}>Mobile</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.map((partner) => {
                        return (<tr key={partner.id}>
                            <td className=' p-3 border-b'>{partner.doc.name}</td>
                            <td className=' p-3 border-b'>{partner.doc.mobile}</td>
                            <td className="p-3 border-b">
                                <button
                                    className="py-1 px-3 text-sm bg-indigo-700 text-white rounded"
                                    type="button"
                                    onClick={() => { props.editHandler(partner) }}>
                                    Edit
                                </button>
                            </td>
                        </tr>)
                    })
                }
            </tbody>
        </table>
    )
}

export default DelPartnerList