import React from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
const UserMap = (props) => {
 
  const { isLoaded } = useLoadScript({
   
    googleMapsApiKey: "AIzaSyCRYNVgK6GhdlUvhO3UUOZmO_SwQ_NY3zI"
  })
  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  const center = {...props.data}
  if (!isLoaded) { 
    return <p>loading...</p>
  }
  return (
    <GoogleMap zoom={18} center={center} mapContainerStyle={containerStyle}>
     <Marker position={center}/>
    </GoogleMap>
  )
}

export default UserMap