import React, { useState, useEffect } from "react";
import { db } from "../../../firebase-config";
import { updateDoc,  doc } from "firebase/firestore";

const VendorProdEdit = (props) => {
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState("");

  const [loading, setLoading] = useState(false);

  const updateProd = (sts) => {
    const vendorProdRef = doc(db, "vendorproducts", props.data.id);
    setLoading(true);
    let dataUpdate =
      sts === "NA"
            ? {
                status: "Unavailable",
                updated: false
            }
        : {
            name,
            unit,
            price,
            retailPrice:(Number(price)*0.05)+(Number(price)),
            status: "available",
            updated: true,
          };
    updateDoc(vendorProdRef, dataUpdate)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    let { name, price, unit } = props.data.doc;
    setName(name);
    setPrice(price);
    setUnit(unit);
  
  }, []);

  return (
    <tr>
      <td className="p-1 border">
        <input
          type="text"
          placeholder="Tomato"
          className=" p-2 w-full"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </td>
      <td className="p-1 border">
        <select
          className=" p-2 w-full"
          onChange={(e) => {
            setUnit(e.target.value);
          }}
          defaultValue={unit}
        >
          <option value="KG">KG</option>
          <option value="PIECE">PIECE</option>
        </select>
      </td>
      <td className="p-1 border">
        <input
          type="number"
          placeholder="20.00"
          className=" p-2 w-full"
          onChange={(e) => {
            setPrice(e.target.value);
          }}
          value={price}
        />
      </td>
    
      <td className="p-1 border text-center">
        {loading ? (
          <p>Please wait ...</p>
        ) : (
          <>
            {" "}
            <button
              className="bg-indigo-600 text-white py-1 px-3 rounded mr-2"
              onClick={updateProd}
            >
              {"Update"}
            </button>
            <button
              className="border border-red-600  py-1 px-3 rounded text-red-500 hover:bg-red-500 hover:text-white"
              onClick={() => {
                updateProd("NA");
              }}
            >
              {"Unavailable"}
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

export default VendorProdEdit;
