/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  addDoc,
  doc,
  collection,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase-config";

export default function AddPartnerModal(props) {
  
  const [open, setOpen] = useState(true);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const cancelButtonRef = useRef(null);

  useEffect(() => {
   
    if (props.data !== null) {
      let { name, mobile} = props.data.doc;
      setName(name);
      setMobile(mobile);
    }

  }, [props.data]);

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);

    if (props.data === null) {
      const delRef = collection(db, "delpartners");
      addDoc(delRef, { name,mobile })
        .then((res) => {
          if (res.id !== "") {
            setStatus("success");
            setTimeout(() => {
              setOpen(false);
            }, 3000);
          } else {
            setStatus("error");
          }
          setLoading(false);
        })
        .catch((err) => {
          setStatus("error");
          setLoading(false);
          console.log(err);
        });
    } else {
      const delref = doc(db, "delpartners", props.data.id);
      updateDoc(delref, {name,mobile})
        .then((res) => {
          setStatus("success");
          setLoading(false);
        })
        .catch((err) => {
          setStatus("error");
          setLoading(false);
          console.log(err);
        });
    }
  };

  const resetState = () => {
    props.resetActivePartner();
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={resetState}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <form onSubmit={handleSubmit}>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      {status === "success" ? (
                        <div className="bg-green-500 text-white p-2 rounded mb-4">
                         Partner added/updated successfully!
                        </div>
                      ) : null}
                      {status === "error" ? (
                        <div className="bg-red-500 text-white p-2 rounded mb-4">
                          Sorry there was an error while adding partner
                        </div>
                      ) : null}
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Add Partner
                      </Dialog.Title>

                      <div className="mt-2 border-t pt-4">
                        <label>Name</label>
                        <input
                          type="text"
                          className="border block p-2 rounded w-full"
                        
                          defaultValue={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                                          </div>
                                          
                                          <div className="mt-2  pt-4">
                        <label>Mobile No</label>
                        <input
                          type="text"
                          className="border block p-2 rounded w-full"
                          placeholder="9999XXXXX"
                          defaultValue={mobile}
                          onChange={(e) => {
                            setMobile(e.target.value);
                          }}
                        />
                      </div>
                   



             
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    
                      disabled={loading ? true : false}
                    >
                      {loading ? "Please wait.." : "Submit"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        resetState();
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
