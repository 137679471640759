import React,{useState,useEffect} from "react";
import { db } from "../../../firebase-config";
import { collection,getDocs } from "firebase/firestore";

const VendorProducts = () => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);


    useEffect(() => {
        const getProducts = () => {
            const prodRef = collection(db, 'vendorproducts');
            getDocs(prodRef).then((res) => {
                const list = res.docs.map((doc) => ({ doc: doc.data(), id: doc.id }));
                setProducts(list);
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
                console.log(err)
            })
        }
        getProducts();

    }, []);

    if (loading) { 
        return <p>Please wait...</p>
    }
  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Products</h2>

        <select className="rounded border p-1">
          <option value="">sort by vendor</option>
          <option>Ramesh Vendor</option>
          <option>ABC-Vendor</option>
        </select>
      </div>
      <table className="w-full text-sm  text-gray-500 rounded mt-4 border">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 rounded-lg">
          <tr>
            <th className=" text-left p-3">Name</th>
            <th className=" text-left p-3">Unit</th>
            <th className=" text-left p-3">Wholesale Price</th>
            <th className=" text-left p-3">Retail Price</th>
            <th className=" text-left p-3">Vendor</th>
          </tr>
        </thead>
              <tbody>
                  {
                      products.map((prod) => { 
                          return (
                            <tr key={prod.id} className="border-b">
                                  <td className=" text-left p-3">{prod.doc.name}</td>
                                  <td className=" text-left p-3">{prod.doc.unit}</td>
                              <td className=" text-left p-3">INR {prod.doc.price}.00</td>
                              <td className=" text-left p-3">INR {prod.doc.price}.00</td>
                            <td className=" text-left p-3">Ramesh Vendor</td>
                          </tr>
                          )
                      })
                  }
          
        </tbody>
      </table>
    </div>
  );
};

export default VendorProducts;
