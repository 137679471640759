import React, {useState} from 'react'
import AddPartnerModal from './AddPartnerModal';
import DelPartnerList from './DelPartnerList'

const DeliveryPartners = () => {
const [show, setShow] = useState(false);
  const [selectedPartner,setSelectedPartner] = useState(null)
  const resetActivePartner = () => {
    setShow(false);
   
  };
  const editHandler = (data) => {
    setShow(true)
    setSelectedPartner(data);
   }
  return (
      <div>
          
          <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">Delivery partners list</h3>
        <button
          className="bg-indigo-700 text-white px-2 py-1 rounded hover:bg-indigo-900"
          onClick={() => {
            setSelectedPartner(null)
            setShow(true);
          }}
        >
          + Add Partner
        </button>
      </div>
          
          <DelPartnerList editHandler={editHandler} />
      
          {show ? <AddPartnerModal data={selectedPartner} resetActivePartner={resetActivePartner} />:null}
    </div>
  )
}

export default DeliveryPartners