import React, {useEffect } from "react";

const GeoLocation = (props) => {

    let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };
    function success(pos) {
        var crd = pos.coords;
        
        props.getCoordinates(crd.latitude,crd.longitude)
    }

    function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    useEffect(() => {

        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    if (result.state === "granted") {
                      
                        //If granted then you can directly call your function here
                        navigator.geolocation.getCurrentPosition(success);
                    } else if (result.state === "prompt") {
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "denied") {
                        //If denied then you have to show instructions to enable location
                    }
                    result.onchange = function () {
                       
                    };
                });
        } else {
            alert("Sorry Not available!");
        }

    }, [])

    return (
        <div className="mt-4">
            <h2>Your location on map</h2>
        </div>
    );

}

export default GeoLocation;