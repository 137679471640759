import {useState,useEffect} from 'react'
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase-config";
const VendorList = ({ setActiveVendor, setShow }) => {
    const [vendors, setVendors] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getVendors = () => {
          const vendorRef = collection(db, "vendors");
          getDocs(vendorRef)
            .then((res) => {
              const list = res.docs.map((doc) => ({
                doc: doc.data(),
                id: doc.id,
              }));
              setVendors(list);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        };
        getVendors();
    }, []);
    
    if (loading) {
        return <div>Please wait...</div>;
      }
  return (
    <table className=" w-full mt-4 border-collapse">
    <thead>
      <tr>
        <th className="text-left border p-2">Name</th>
        <th className="text-left border p-2">Address</th>
        <th className=" border p-2">Status</th>
      </tr>
    </thead>
    <tbody>
      {vendors.map((vendor) => {
        return (
          <tr
            key={vendor.id}
            className=" hover:bg-sky-50 cursor-pointer"
            onClick={() => {
              setActiveVendor(vendor);
              setTimeout(() => { setShow(true);},200)
            }}
          >
            <td className="border p-2">{vendor.doc.name}</td>
            <td className="border p-2">{vendor.doc.address}</td>
            <td className="border p-2 text-center">
              {vendor.doc.status === "true" ? (
                <span className="bg-green-400 py-1 px-2 rounded">
                  Active
                </span>
              ) : (
                <span className="bg-red-400 py-1 px-2 rounded">
                  In Active
                </span>
              )}
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
  )
}

export default VendorList