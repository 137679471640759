import React from "react";
import Product from "./Product";

const ProductsList = ({ products }) => {
  return (
    <div className="grid m-4 grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 md:m-0 md:gap-6">
      {products.map((product, i) => {
        return <Product data={product} key={i} />;
      })}
    </div>
  );
};

export default ProductsList;
