import React from "react";
import Layout from "../common/Layout";
const About = () => {
  return (
    <Layout>
      <div className="w-4/6 mx-auto">
        <h1 className="text-2xl font-semibold border-b pb-4">Why choose PassingtheParcel?</h1>
        <p className=" py-4">
          Are you tired of constantly having to make trips to the grocery store for fresh vegetables? Look no further than our fresh vegetable delivery service!</p>
        
        <p className="pt-4">

At <b>PassingtheParcel,</b> we understand the importance of incorporating fresh vegetables into your daily diet. That's why we're dedicated to providing our customers with the freshest and most affordable vegetables on the market.</p>
<p className="pt-4">
Our team handpicks the best produce from local farmers to ensure that our customers receive the highest quality vegetables. Plus, by sourcing our produce locally, we're able to reduce the carbon footprint of our delivery service and support our community.
        </p>
        <p className="pt-4">
But our commitment to freshness and quality doesn't mean that our prices are out of reach. In fact, we're proud to offer our vegetable delivery service at prices that are competitive with traditional grocery stores.
        </p>
        <p className="pt-4">
But don't just take our word for it, try our service today and taste the difference for yourself. Sign up for a one-time delivery or set up a regular delivery schedule that works for you. We even offer a subscription service where you can receive a box of fresh vegetables every week. <br/><br/>

          Make the switch to fresh, affordable vegetable delivery with <b>PassingtheParcel</b> today! Your taste buds and your wallet will thank you.</p>
        

      </div>
    </Layout>
  );
};

export default About;
