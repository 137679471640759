export const typeCheck = (uid) => {
  const allowed = ["A6IUJN526QXEjNabCjWKlyvrWLt1"];
  if (allowed.indexOf(uid) !== -1) {
    return true;
  }
};

export const images = [
  {
    name: " beetroot",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/Beet-Root-HD-Image.jpg?alt=media&token=0d0e0aa9-c3cd-49ed-b5f6-3b319e043ba3",
  },
  {
    name: "drumstick",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/Drumstick.jpg?alt=media&token=30be6de2-5197-48c1-b474-86ffe8668611",
  },
  {
    name: "brinjal",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/brinjal.jpg?alt=media&token=e595bc92-43d6-4437-9a84-38d046ee2fa4",
  },
  {
    name: "carrot",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/carrot.jpg?alt=media&token=7059f66c-64a9-4c5f-b55e-4a855d3265af",
  },
  {
    name: "coriander",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/corainder.jpg?alt=media&token=027526ce-c9bb-4e5c-973f-177a18758490",
  },
  {
    name: "ladyfinger",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/lady-finger.jpg?alt=media&token=98e42a2e-689c-4ad6-836a-229093617d31",
  },
  {
    name: "pudhina",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/mintpudina.jpg?alt=media&token=6c75abc9-0e92-4d6a-9c3e-7f19cabd8648",
  },
  {
    name: "onion",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/onion.jpg?alt=media&token=20d1e7b6-d80a-4161-8f1f-988e722ddbae",
  },
  {
    name: "potato",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/potatoes-500x500.jpg?alt=media&token=930e7440-e820-4a30-9a70-d6cf83dbf6fc",
  },
  {
    name: "pumpkin",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/pumpkin.jpg?alt=media&token=f2b1e164-d182-4a55-9e3e-70702b2a212f",
  },
  {
    name: "radish",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/radish-long-white-icicle-1000-seeds.jpg?alt=media&token=07b5e1ea-e192-41e2-9b54-565d78692d88",
  },
  {
    name: "tomato",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/tomato.jpg?alt=media&token=62d68686-1feb-452e-a0d2-bcbbd08a9d3c",
  },
  {
    name: "chowchow",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/Chow-Chow.jpg?alt=media&token=d3129825-6048-4b2a-b48e-91b81d439339",
  },
  {
    name: "Beans",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/beans.jpg?alt=media&token=cf488646-ef82-4ba6-bdeb-37b6ec9bb2eb",
  },
  {
    name: "capsicum",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/capsicum.jpg?alt=media&token=1a626e4a-35d3-45d1-9b21-892e07e81f04",
  },
  {
    name: "chilly",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/chilly.jpg?alt=media&token=52b29217-47f4-4334-ab32-9bf54c282de5",
  },
  {
    name: "Ginger",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/ginger.jpg?alt=media&token=0358d166-3dd8-4e04-a200-71cd8b07e6d8",
  },
  {
    name: "ladyfinger",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/lady-finger.jpg?alt=media&token=98e42a2e-689c-4ad6-836a-229093617d31",
  },
  {
    name: "mango",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/mango.jpg?alt=media&token=9ab0baf4-a0cb-4eda-ad6a-0dff1f56bb9c",
  },
  {
    name: "radish",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/radish-long-white-icicle-1000-seeds.jpg?alt=media&token=07b5e1ea-e192-41e2-9b54-565d78692d88",
  },
  {
    name: "small onion",
    link: "https://firebasestorage.googleapis.com/v0/b/passingparcel-8e6eb.appspot.com/o/test-Shallots-Small-Onion.jpg?alt=media&token=b40c5d81-2567-47a6-96b4-797b5777c698",
  },
];
