import React, { useState, useEffect } from "react";
import Layout from "../../common/Layout";
import OrderList from "./OrderList";
import ProductInventory from "./ProductInventory";
import TabHeader from "../../common/TabHeader";
import Vendors from "./Vendors";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Invoices from "./Invoices";
import DeliveryPartners from "./DeliveryPartners";
import VendorProducts from "./VendorProducts";
const AdminDashboard = () => {
  const [activeStatus, setActiveStatus] = useState(1);
  let history = useNavigate();

  useEffect(() => {
    let cookies = new Cookies();
    let parcel = cookies.get("parcel");
    if (parcel === undefined) {
      history("/");
    }
  }, []);

  return (
    <Layout>
      <div>
        {/* <div className="sm:hidden relative w-11/12 mx-auto bg-white rounded">
          <div className="absolute inset-0 m-auto mr-4 z-0 w-6 h-6"></div>
          <select className="form-select block w-full p-3 border border-gray-300 rounded text-gray-600 appearance-none bg-transparent relative z-10">
            <option className="text-sm text-gray-600">inactive</option>
            <option className="text-sm text-gray-600">inactive</option>
            <option selected className="text-sm text-gray-600">
              Active
            </option>
            <option className="text-sm text-gray-600">inactive</option>
            <option className="text-sm text-gray-600">inactive</option>
          </select>
        </div> */}
        <div className="xl:w-full xl:mx-0 h-12 hidden sm:block ">
          <ul className="flex  px-5">
            <TabHeader
              id={1}
              setActiveStatus={setActiveStatus}
              title="Live Products inventory"
              activeStatus={activeStatus}
            />
            <TabHeader
              id={2}
              setActiveStatus={setActiveStatus}
              title="Orders list"
              activeStatus={activeStatus}
            />
            <TabHeader
              id={3}
              setActiveStatus={setActiveStatus}
              title="Invoices"
              activeStatus={activeStatus}
            />

            <TabHeader
              id={4}
              setActiveStatus={setActiveStatus}
              title="Wholesalers"
              activeStatus={activeStatus}
            />
            <TabHeader
              id={5}
              setActiveStatus={setActiveStatus}
              title="Del Partners"
              activeStatus={activeStatus}
            />
             <TabHeader
              id={6}
              setActiveStatus={setActiveStatus}
              title="Vendor products"
              activeStatus={activeStatus}
            />
          </ul>

          {activeStatus === 1 ? (
            <div className="border m-4 p-4">
              <ProductInventory />
            </div>
          ) : null}
          {activeStatus === 2 ? (
            
              <OrderList />
           
          ) : null}
          {activeStatus === 3 ? (
            <div className="border m-4 p-4">
              <Invoices />
            </div>
          ) : null}
          {activeStatus === 4 ? (
            <div className="border m-4 p-4">
              <Vendors />
            </div>
          ) : null}
          {activeStatus === 5 ?
              (<div className="border m-4 p-4"><DeliveryPartners /></div>
            ) : null}
          {activeStatus === 6 ?
              (<div className="border m-4 p-4"><VendorProducts/></div>
              ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
