import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase-config";

const ProductsList = (props) => {

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [vendors, setVendors] = useState([]);

    

    useEffect(() => {

        const getProducts = () => {

            let productRef = collection(db, "products");
    
            getDocs(productRef)
                .then((res) => {
                    const list = res.docs.map((doc) => ({
                        doc: doc.data(),
                        id: doc.id,
                    }));
    
                    setProducts(list);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        };
    
        const getVendors = () => {
            const vendorRef = collection(db, 'vendors')
            getDocs(vendorRef).then((res) => {
                const list = res.docs.map((doc) => ({ doc: doc.data(), id: doc.id }));
                //console.log(list);
                setVendors(list)
            })
        }
        getVendors();
        getProducts();
        
    }, []);

    const findVendorName = (id) => {
        let vendorName = '';


        for (let i = 0; i < vendors.length; i++) { 
            if (id === vendors[i]["id"]) { 
                vendorName = vendors[i]["doc"]["name"];
                break;
            }
        }

        return vendorName
    }

    if (loading) {
        return <p>Please wait...</p>;
    }

    return (
        <table className="w-full border mt-4 text-sm">
            <thead>
                <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase  ">
                    <th className="text-left font-medium p-4">Image</th>
                    <th className="text-left font-medium p-4">Name</th>
                    <th className="text-left font-medium p-4">Description</th>
                    <th className="text-left font-medium p-4">Price</th>
                    <th className="text-left font-medium p-4">Minimum</th>
                    <th className="text-left font-medium p-4">Unit</th>
                    <th className="text-left font-medium p-4">Vendor</th>
                    <th className="text-left font-medium p-4">Options</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product, i) => {
                    return (
                        <tr key={i} className="border-b">
                            <td className="p-4">
                                <img src={product.doc.image} className="w-10" alt="productimage" />
                            </td>
                            <td className="p-4">{product.doc.title}</td>
                            <td className="p-4">{product.doc.description}</td>
                            <td className="p-4">{product.doc.price}.00</td>
                            <td className="p-4">{product.doc.minQuantity}</td>
                            <td className="p-4">{product.doc.unit}</td>
                            <td className="p-4">{findVendorName(product.doc.vendor)}</td>
                            <td className="p-4">
                                <button className="py-1 px-3 text-sm bg-indigo-700 text-white rounded" type="button" onClick={() => {props.editHandler(product) }}>
                                    Edit
                                </button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default ProductsList;
