/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import AddProductVoice from "./AddProductVoice";
import { db } from "../../../firebase-config";
import { updateDoc, doc } from "firebase/firestore";
import { useEffect } from "react";

export default function VoiceModal(props) {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  // const [title, setTitle] = useState("");
  const [wPrice, setWPrice] = useState("");
  // const [rPrice, setRPrice] = useState("");
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    props.resetActiveProduct();
    setOpen(false);
  };

  const setPropData = (transcript) => {
    // if (title === "") {
    //   setTitle(transcript);
    // }
    if (wPrice === "") {
      setWPrice(transcript);
    }
   // else {
    //   setRPrice(transcript);

    // }
  };

  const sayAgain = () => {
    //setTitle("");
    setWPrice("");
    //setRPrice("");
  };

  useEffect(() => {
    const updateProd = () => {
     

      const vendorProdRef = doc(db, "vendorproducts", props.selectedProd.id);
      setLoading(true);
      updateDoc(vendorProdRef, {
        name: props.selectedProd.doc.name,
        price: wPrice,
        retailPrice: (Number(wPrice)*0.05)+(Number(wPrice)),
      })
        .then((res) => {
          setTimeout(() => {
            setLoading(false);
            props.resetActiveProduct();
          }, 2500);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    if (wPrice !== "") {
      updateProd();
    }
  }, [wPrice]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={resetState}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {loading ? (
                  <div className="text-center p-12 font-semibold">
                    Updating product please wait...
                  </div>
                ) : (
                  <div className="p-12">
                    {wPrice !== "" ? (
                      <div className="text-center">
                        <p className="text-lg text-center">
                          <span className=" capitalize font-semibold">
                            {props.selectedProd.doc.name}
                          </span>
                          's wholesale price is INR{" "}
                          <span className="font-semibold">{wPrice}</span> 
                         
                         
                        </p>
                        <button
                          className=" border-indigo-500 border-dashed border-b text-indigo-600 hover:text-indigo-800"
                          onClick={sayAgain}
                        >
                          Want to say again?
                        </button>
                      </div>
                    ) : null}

                   
<AddProductVoice
                        setPropData={setPropData}
                        type="wprice"
                      />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
