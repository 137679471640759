import React from "react";

const TabHeader = (props) => {
  return (
    <li
      onClick={() => props.setActiveStatus(props.id)}
      className={
        props.activeStatus === props.id
          ? "text-sm border-indigo-700 pt-3 rounded-t text-indigo-700 mr-12"
          : "text-sm text-gray-600 py-3 flex items-center mr-12 hover:text-indigo-700 cursor-pointer"
      }
    >
      <div className="flex items-center mb-1">
        <span className=" font-normal">{props.title}</span>
      </div>
      {props.id === props.activeStatus && (
        <div className="w-full h-1 bg-indigo-700 "></div>
      )}
    </li>
  );
};

export default TabHeader;
