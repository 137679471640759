import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyABmJmf40MBIYz0mi5y2BsLRxobof9NBcE",
  authDomain: "passingparcel-8e6eb.firebaseapp.com",
  projectId: "passingparcel-8e6eb",
  storageBucket: "passingparcel-8e6eb.appspot.com",
  messagingSenderId: "457909562674",
  appId: "1:457909562674:web:421b063f1fcdde03d57b2b",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
