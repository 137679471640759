import { useState } from "react";
import VendorList from "./VendorList";
import VendorModal from "./VendorModal";

const Vendors = () => {
  
  const [show, setShow] = useState(false);
  const [activeVendor, setActiveVendor] = useState("true");

  const resetActiveVendor = () => {
    setShow(false);
    setActiveVendor(null);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Wholesalers list</h2>

        <button
          className="bg-indigo-700 text-white px-2 py-1 rounded hover:bg-indigo-900"
          onClick={() => {
            setShow(!show);
          }}
        >
          + Add Vendor
        </button>
      </div>

      <VendorList setShow={setShow} setActiveVendor={setActiveVendor} />

      {show ? (
        <VendorModal
          data={activeVendor}
          resetActiveVendor={resetActiveVendor}
        />
      ) : null}
    </div>
  );
};

export default Vendors;
