import React, { useState } from "react";
import CartItem from "../common/CartItem";
import Layout from "../common/Layout";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addDoc, collection,serverTimestamp,getDocs,query, where } from "firebase/firestore";
import { db } from "../../firebase-config";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { emptyCart } from "../../features/cart";
import Payment from "../common/Payment";

const Cart = (props) => {
 

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const history = useNavigate();
  const dispatch = useDispatch();

  const totalPrice = () => {
    let total = 0;

    for (let i = 0; i < props.cart.value.length; i++) {
      total +=
        (Number(props.cart.value[i].doc.retailPrice)*(props.cart.value[i].minUnit)) *
        Number(props.cart.value[i].quantity);
    }
    return `INR ${total.toFixed(2)}`;
  };

  const getTotal = () => {
    let total = 0;

    for (let i = 0; i < props.cart.value.length; i++) {
      total +=
        (Number(props.cart.value[i].doc.retailPrice)*(props.cart.value[i].minUnit)) *
        Number(props.cart.value[i].quantity);
    }
    return Number(total)
  };
  const placeOrder = () => {
    let items = props.cart.value;
    let user = props.authentication.value.uid;
    let orderRef = collection(db, "orders");
   
    const q = query(collection(db, "userdata"),where("userid", '==', user));

    let orderID = new moment().format('dddDDMMYY').toUpperCase();
    let count = 0;
    
  
    getDocs(orderRef).then((re) => {

      count = re.docs.length+1;
   
      let orderDate = new moment().format('DD-MMM-YYYY hh:mm A');

      getDocs(q).then((response) => {
        const list = response.docs.map((doc) => ({
          doc: doc.data(),
          id: doc.id,
        }));

        let vendors = [];

        for (let i = 0; i < props.cart.value.length; i++) { 
          let vendorID = props.cart.value[i].doc.vendor;
         

          if (vendors.indexOf(vendorID) === -1) { 
            vendors.push(vendorID)
          }
        }
            
        addDoc(orderRef, {
          user,
          items,
          status: 'In Progress',
          timestamp: serverTimestamp(),
          orderID: `${orderID}-${count}`,
          orderDate,
          total: totalPrice(),
          address: list[0].doc.address,
          coords: list[0].doc.coords,
          dpartner: 'Not assigned',
          vendors:vendors
        })
        .then((res) => {
        
          setLoading(false)
          setStatus('success')

          setTimeout(() => { 
            dispatch(emptyCart())
            history('/dashboard')
          },3000)
        })
        .catch((err) => {
          console.log(err);
          setLoading(false)
          setStatus('error')
        });
       
       }).catch((err) => { console.log(err)})

   
      
    }).catch((err) => { console.log(err) })
  
    setLoading(true)
    
  };
  if (props.cart.value.length < 1) { 

    return (<Layout>
       <div className="w-5/6">
        <h3 className="text-xl font-semibold text-red-500">Your cart is empty</h3>
        <p>
         Please add items to view them in cart.
        </p>
        </div>
    </Layout>)
  }
  return (
    <Layout>
      <div className="w-5/6 mx-auto">
        <h3 className="text-xl font-semibold">Items in your cart.</h3>
        <p>
          This cart contains items you have selected and total price of your
          order
        </p>
        <div className="border mt-4 rounded-lg">
          {props.cart.value.map((item, i) => {
            return <CartItem key={i} item={item} />;
          })}

          <div className="text-right p-4">
            <span>Order total</span>
            <p className="text-xl font-semibold ">{totalPrice()}</p>
          </div>
          <div className="flex border-t py-5 justify-between">
            <div className="p-4">
              {status === 'error' ? <p className="bg-red-500 text-white p-2">Sorry order could not be placed try again.</p> : null}
              {status === 'success' ? <p className="bg-green-500 text-white p-2">Your order has been place successfully</p> : null}
            </div>
            <div className="p-4">

           
              {props.authentication.value.email ? (
                // <button
                //  onClick={placeOrder}
                //   className=" bg-blue-600 text-white rounded-full py-3 px-5 hover:bg-indigo-700"
                // >
                //   { loading ? 'Please wait..':'Place order '}
                //   <ArrowRightIcon className="w-5 h-5 inline-block" />
                // </button>
                <Payment amount={getTotal()} placeOrder={placeOrder} />
              ) : (
                <Link
                  to="/login"
                  className=" bg-blue-600 text-white rounded-full py-3 px-5 hover:bg-indigo-700"
                >
                  Sign in to Place order{" "}
                  <ArrowRightIcon className="w-5 h-5 inline-block" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
const mapStatetoProps = (state) => state;

export default connect(mapStatetoProps)(Cart);
