
import React from 'react';
import axios from "axios";
import { connect } from "react-redux";
const Payment = (props) => {

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        console.log(props.amount)

        const result = await axios.post("https://razorpaynode-production.up.railway.app/createorder", {amount:200});

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        const { amount, id: order_id, currency } = result.data;
        //console.log(props.amount.toFixed(2),amount)

        const options = {
            key: "rzp_test_xLR8wp9LUamUB7", // Enter the Key ID generated from the Dashboard
            amount: 200.00,
            currency: currency,
            name: "PassingtheParcel.",
            description: "Payment for grocery order",
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };
                // console.log(data)
                props.placeOrder()
                // const result = await axios.post("http://localhost:5000/payment/success", data);

                // alert(result.data.msg);
            },
            prefill: {
                name: props.authentication.value.name,
                email: props.authentication.value.email,
                contact: "9999999999",
            }, 
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }



    return (
        <div className="App">
            <header className="App-header">
              
              
                <button className=" bg-blue-600 text-white rounded-full py-3 px-5 hover:bg-indigo-700" onClick={displayRazorpay}>
                    Pay ₹ {props.amount}
                </button>
            </header>
        </div>
    );
}

const mapStatetoProps = state => state;

export default connect(mapStatetoProps)(Payment);
