import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../features/authentication";
import { useNavigate } from "react-router-dom";
import {connect} from 'react-redux'

function ProfileMenu(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const logoutHandler = () => {
    dispatch(logout());
    history("/");
  };
  return (
    <Menu as="div" className="relative inline-block text-left ml-2">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          Welcome! <b className="pl-2 capitalize">{props.authentication.value.name}</b>
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            
            {
              props.authentication.value.parcel ? null :<Menu.Item>
              <Link
                to="/dashboard"
                className="hover:bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
              >
                My orders
              </Link>
            </Menu.Item>
            }
          </div>
          <div className="py-1">
            <Menu.Item>
              <Link
                to="/"
                className="hover:bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
              >
                Support
              </Link>
            </Menu.Item>
          </div>

          <div className="py-1">
            <Menu.Item>
              <Link
                to="/logout"
                className="hover:bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
                onClick={logoutHandler}
              >
                Logout
              </Link>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}


const mapStatetoProps = state => state;

export default connect(mapStatetoProps)(ProfileMenu)
