import React, { useEffect, useState } from "react";
import { db } from "../../../firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import Cookies from "universal-cookie";
import VoiceModal from "./VoiceModal";


const VendorProductVoiceList = () => {
  const [products, setProducts] = useState([]);
  const [prodLoading, setProdLoading] = useState(true);
  const [selectedProd, setSelectedProd] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const getProducts = () => {
      let cookies = new Cookies();
      let vendor = cookies.get("vendor");
      const vendorProdRef = query(
        collection(db, "vendorproducts"),
        where("vendor", "==", vendor)
      );
      getDocs(vendorProdRef)
        .then((res) => {
          const list = res.docs.map((doc) => ({ id: doc.id, doc: doc.data() }));
          setProducts(list);
          setProdLoading(false);
        })
        .catch((err) => {
          setProdLoading(false);
          console.log(err);
        });
    };
    getProducts();
  }, []);
  const getProducts = () => {
    let cookies = new Cookies();
    let vendor = cookies.get("vendor");
    const vendorProdRef = query(
      collection(db, "vendorproducts"),
      where("vendor", "==", vendor)
    );
    getDocs(vendorProdRef)
      .then((res) => {
        const list = res.docs.map((doc) => ({ id: doc.id, doc: doc.data() }));
        setProducts(list);
        setProdLoading(false);
      })
      .catch((err) => {
        setProdLoading(false);
        console.log(err);
      });
  };
  const resetActiveProduct = () => {
    setShow(false);
    setSelectedProd(null);

    getProducts();
  };
  const activateVoice = (prod) => {
    setSelectedProd(prod);
    setShow(true);
  };
  if (prodLoading) {
    return <div className="p-4">Please wait..</div>;
  }
  return (
    <section className="container mx-auto p-6 font-mono">
      <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
        <div className="w-full overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
                <th className="text-left px-4 py-3">Name</th>
                <th className="text-left px-4 py-3">Unit</th>
                <th className="text-left px-4 py-3">Wholesale price</th>
                {/* <th className="text-left px-4 py-3">Retail price</th> */}
                <th className="text-center px-4 py-3">Update</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {products.map((prod, i) => {
                return (
                  <tr key={i}>
                    <td className="px-4 py-3 border capitalize">
                      {prod.doc.name}
                    </td>
                    <td className="px-4 py-3 border">{prod.doc.unit}</td>
                    <td className="px-4 py-3 border">INR {prod.doc.price}</td>
                    {/* <td className="px-4 py-3 border">
                      INR {prod.doc.retailPrice}
                    </td> */}
                    <td className="px-4 py-3  border text-center">
                      <button
                        className="bg-indigo-600 text-white py-1 px-3 rounded mr-2 hover:bg-indigo-800"
                        onClick={() => {
                          activateVoice(prod);
                        }}
                      >
                        
                        Speak
                      </button>

                      <button className="border border-red-600 text-red-600 py-1 px-3 rounded hover:bg-red-500 hover:text-white">
                        {"Unavailable"}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {show ? (
          <VoiceModal
            resetActiveProduct={resetActiveProduct}
            selectedProd={selectedProd}
          />
        ) : null}
      </div>
    </section>
  );
};

export default VendorProductVoiceList;
