import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../common/Layout";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase-config";
import GeoLocation from "../common/GeoLocation";
import UserMap from "../common/UserMap";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase-config";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [coords, setCoords] = useState(null);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('')
  const history = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const user = await createUserWithEmailAndPassword(auth, email, password);
      if (user) {
        const userDataRef = collection(db, "userdata");
        addDoc(userDataRef, { name, mobile, address, coords: {...coords }, userid: user.user.uid }).then(() => {

          
          history("/dashboard");
        }).catch((err) => { console.log(err); setLoading(false); })


      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCoordinates = (lat, lng) => {
    setCoords({ lat, lng });
  
  }
  return (
    <Layout>
      <div className="w-1/2 mx-auto border p-5 rounded">
        <h3 className="text-lg">
          Welcome to <b>Passingtheparel</b>.
          <span className="text-gray-500  block">
            Your vegetable & fruits shopping destination.
          </span>
        </h3>
        <p className="mt-2 text-sm">
          Fill in the details below to register for an free account and place
          orders.
        </p>

        <form className="mt-5" onSubmit={submitHandler}>
          <div className="grid mt-4 grid-cols-2 gap-2">
            <div className="mr-2">
              <label>Email</label>
              <input
                type="text"
                className="border border-gray-300 rounded block p-1 w-full"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
            </div>
            <div>
              <label>Password</label>
              <input
                type="text"
                className="border border-gray-300 rounded block p-1 w-full"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
              />
            </div>
          </div>

          <div className="grid mt-4 grid-cols-2 gap-2">
            <div className=" mr-2">
              <label>Mobile</label>
              <input
                type="text"
                className="border border-gray-300 rounded block p-1 w-full"
                required
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
            </div>
            <div>
              <label>Name</label>
              <input
                type="text"
                className="border border-gray-300 rounded block p-1 w-full"
                required
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="grid mt-4 grid-cols-1 gap-2">
            <div>
              <label>Address</label>
              <textarea className="border border-gray-300 rounded block p-1 w-full" required onChange={(e) => {
                setAddress(e.target.value);
              }} />
              <textarea type="hidden" className="hidden"></textarea>
            </div>
          </div>
          <GeoLocation getCoordinates={getCoordinates} />
          { coords !==null ? <UserMap data={coords} /> :null }
          

          <div className="mt-5 pt-3">
            <button
              type="submit"
              className="bg-blue-700 text-white rounded px-4 py-2"
            >
              {loading ? "Please wait.." : "Register"}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Register;
