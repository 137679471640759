import "./App.css";
import Layout from "./components/common/Layout";
import ProductsList from "./components/ProductsList";

import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase-config";
import SearchProducts from "./components/common/SearchProducts";

function App() {
  const [products, setProdList] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const setProdCallBack = (prod) => {   
    setFiltered(prod)
  }

  useEffect(() => {
    const prodRef = collection(db, "products");
    getDocs(prodRef)
      .then((res) => {
        const list = res.docs.map((doc) => ({
          doc: doc.data(),
          id: doc.id,
        }));
      
        setProdList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
 
  return (
    <div className="App mb-8">
      <Layout>
        <div className="flex w-full justify-between mb-4">
          <h3 className="text-xl mb-3 w-2/3">Available products</h3>
          <SearchProducts setProdCallBack={setProdCallBack} products={products} />
        </div>
        <ProductsList products={filtered.length>0? filtered : products}  />
      </Layout>
    </div>
  );
}

export default App;
