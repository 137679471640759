import React from "react";
import { signOut } from "firebase/auth";
import { logout } from "../../features/authentication";
import { useDispatch } from "react-redux";
import { auth } from "../../firebase-config";
import Layout from "../common/Layout";
import { updatePage } from "../../features/activePage";
import Cookies from "universal-cookie";
const Logout = () => {
  let cookies = new Cookies();
  cookies.remove("parcel");
  const dispatch = useDispatch();
  const signout = async () => {
    await signOut(auth);
    dispatch(logout(auth.user));
    dispatch(updatePage("logout"));
  };
  signout();
  return (
    <Layout>
      <div className="1/2 mx-auto text-center">
        <p className="text-green-600 font-semibold text-lg">
          You've been successfully logged out
        </p>
      </div>
    </Layout>
  );
};

export default Logout;
