import { createSlice } from "@reduxjs/toolkit";

export const activePage = createSlice({
  name: "activePage",
  initialState: {
    value: "home",
  },
  reducers: {
    updatePage: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updatePage } = activePage.actions;
export default activePage.reducer;
