import React, {useState,useEffect} from 'react'
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import TabHeader from '../../common/TabHeader';
import VendorOrderList from './VendorOrderList';
import VendorProductsList from './VendorProductsList';
import VendorProductVoiceList from './VendorProductVoiceList';
import logo from '../../../assets/logo.svg'
const VendorDashboard = () => {
    const [activeStatus, setActiveStatus] = useState(1);
    let history = useNavigate();

    useEffect(() => {
      let cookies = new Cookies();
      let vendor = cookies.get("vendor");
      if (vendor === undefined) {
     
        history("/");
      }
    }, []);
  return (
    <>
       <div className="border-b border-gray-200">
    <header className='mx-auto max-w-7xl sm:px-6 lg:px-8 h-43  py-2 flex justify-between items-center'>
          <img src={logo} alt="logo" className="w-32 pl-2" />
          <Link to="/login" className='hover:text-indigo-500'>Logout</Link>
        </header>
        </div>
    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 mt-8">
      
      
          <div className="xl:w-full xl:mx-0 h-12 sm:block ">
          <ul className="flex  px-5">
            <TabHeader
              id={1}
              setActiveStatus={setActiveStatus}
              title="Add Products"
              activeStatus={activeStatus}
            />
            <TabHeader
              id={2}
              setActiveStatus={setActiveStatus}
              title="Orders list"
              activeStatus={activeStatus}
          /> 
           <TabHeader
              id={3}
              setActiveStatus={setActiveStatus}
              title="Add Products via Voice"
              activeStatus={activeStatus}
          /> 
         
          </ul>
         
          {activeStatus === 1 ? (
            <div className="border m-4">
            <VendorProductsList/>
            </div>
          ) : null}
          {activeStatus === 2 ? (
           
              <VendorOrderList />
           
        ) : null}
        
        {activeStatus === 3 ? (
           
              <VendorProductVoiceList />
           
          ) : null}
        </div>
      </div>
      </>
  )
}

export default VendorDashboard