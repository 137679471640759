export const findsts = (sts) => {
  
    switch (sts) { 
        case "Delivery Assigned":
            return "bg-purple-500 text-white"
        case "Delivered":
            return "bg-green-500 text-white"
        case "Delayed":
            return "bg-red-500 text-white"
        default:
            return "bg-orange-500 text-white"
    }

 }