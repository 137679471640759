import React from 'react'
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
const SearchProducts = (props) => {

    const onChangeHandler = (term) => {
        
    
        let foundItems = [];
        
        for (let i = 0; i < props.products.length; i++) { 
            let title = (props.products[i].doc.title).toLowerCase();
            if (title.includes(term.toLowerCase())) {
                   foundItems.push(props.products[i])
             }
        }

        props.setProdCallBack(foundItems)

     }
  return (
    <div className="w-1/3 relative">
    <MagnifyingGlassIcon className="w-5 h-6 absolute mt-1 ml-4" />
    <input
      type="text"
      className="border rounded-full pl-10 py-1 w-full "
              placeholder="Search for vegetables or fruits.."
              onChange={(e) => { onChangeHandler(e.target.value)}}
    />
  </div>
  )
}

export default SearchProducts