import React, { useState } from "react";

const AddItem = (props) => {

  const [count, setCount] = useState(0);
  const [min_unit, set_min_unit] = useState(1);
  const onChangeHandler = (type) => {
    if (type === "add") {
      props.cartDataHandler(count + 1,min_unit);
      setCount(count + 1);
    } else {
      if (count > 0) {
        props.cartDataHandler(count - 1,min_unit);
        setCount(count - 1);
      }
    }
  };
  return (
    <>
      <div>
        <select className="w-full border p-2 rounded mb-2" onChange={(e) => {set_min_unit(Number(e.target.value))}}>
          <option value={1}>1 Kg</option>
          <option value={0.75}>750 Gms</option>
          <option value={0.5}>500 Gms</option>
          <option value={0.25}>250 Gms</option>
        </select>
      </div>
      <div className="mx-auto w-1/2 pb-4">
      <div className="flex">
      <button
        className="border 
              border-indigo-600 
               px-3 py-1 
              bg-indigo-600
              hover:bg-red-500
              hover:border-red-500
               text-white rounded-tl-md rounded-bl-md
               "
        onClick={() => {
          onChangeHandler("minus");
        }}
      >
        -
      </button>
      <input
        type="text"
        className="text-center border border-indigo-600 w-1/3 "
        value={count}
        onChange={(e) => {
          setCount(Number(e.target.value));
        }}
      />
      <button
        className="rounded-tr-md 
        rounded-br-md border
         bg-indigo-600
          border-indigo-600
           text-white px-3 py-1
            hover:bg-green-600 hover:border-green-600"
        onClick={() => {
          onChangeHandler("add");
        }}
      >
        +
      </button>
      </div>
        </div>
      </>
  );
};

export default AddItem;
