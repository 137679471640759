import React, { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import {
  Bars3Icon,
  XMarkIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import logo from "../../assets/logo.svg";
import { connect } from "react-redux";
import ProfileMenu from "./ProfileMenu";
import { updatePage } from "../../features/activePage";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase-config";
import { login } from "../../features/authentication";
import { typeCheck } from "./utils";
function Header(props) {
  const [user, setUser] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser !== null) {
        if (typeCheck(currentUser.uid)) {
          dispatch(
            login({
              uid: currentUser.uid,
              email: currentUser.email,
              name: currentUser.email.split("@")[0],
              parcel: true,
            })
          );
        } else {
          dispatch(
            login({
              uid: currentUser.uid,
              email: currentUser.email,
              name: currentUser.email.split("@")[0],
              parcel: false,
            })
          );
        }
      }
    });
  }, []);

  return (
    <Popover className=" bg-white border-b relative">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between  border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link
              to="/"
              onClick={() => {
                dispatch(updatePage("home"));
              }}
            >
              <span className="sr-only">PassingtheParcel</span>
              <img className="h-8 w-auto sm:h-10" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            <Link
              to="/"
              className={`text-base font-medium  hover:text-gray-900  ${
                props.activePage.value === "home"
                  ? "border-indigo-700 text-indigo-700 border-b-4"
                  : "text-gray-500"
              }`}
              onClick={() => {
                dispatch(updatePage("home"));
              }}
            >
              Home
            </Link>
            {props.authentication.value.parcel ? (
              <Link
                to="/admin"
                className={`text-base font-medium  hover:text-gray-900  ${
                  props.activePage.value === "admin"
                    ? "border-indigo-700 text-indigo-700 border-b-4"
                    : "text-gray-500"
                }`}
                onClick={() => {
                  dispatch(updatePage("admin"));
                }}
              >
                Admin Dashboard
              </Link>
            ) : (
              <React.Fragment>
                {" "}
                <Link
                  to="/about"
                  className={`text-base font-medium  hover:text-gray-900  ${
                    props.activePage.value === "about"
                      ? "border-indigo-700 text-indigo-700 border-b-4"
                      : "text-gray-500"
                  }`}
                  onClick={() => {
                    dispatch(updatePage("about"));
                  }}
                >
                  About us
                </Link>
                <Link
                  to="/contact"
                  className={`text-base font-medium  hover:text-gray-900  ${
                    props.activePage.value === "contact"
                      ? "border-indigo-700 text-indigo-700 border-b-4"
                      : "text-gray-500"
                  }`}
                  onClick={() => {
                    dispatch(updatePage("contact"));
                  }}
                >
                  Contact us
                </Link>
              </React.Fragment>
            )}
          </Popover.Group>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <Link
              to="/cart"
              className="rounded flex justify-center items-center whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 border py-2 px-4 "
            >
              <ShoppingCartIcon className="w-5 h-5" />

              <span className="pl-1">
                Cart {props.cart.value.length > 0 ? "" : " is empty"}
              </span>
              {props.cart.value.length < 1 ? null : (
                <span className=" bg-green-600 text-white rounded-md px-2 ml-2">
                  {props.cart.value.length}
                </span>
              )}
            </Link>
            {user?.email !== undefined ? (
              <ProfileMenu />
            ) : (
              <Link
                to="/login"
                className="ml-6 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
              >
                Sign in
              </Link>
            )}
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-20"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="PassingtheParcel"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link
                  to="/about"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  About us
                </Link>
              </div>
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link
                  to="/about"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Contact Us
                </Link>
              </div>
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link
                  to="/contact"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Contact Us
                </Link>
              </div>
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link
                  to="/cart"
                  className="text-base font-medium text-gray-900 hover:text-gray-700 block"
                >
                  <div className="flex">
                    <ShoppingCartIcon className="w-5 h-5" />

                    <span className="pl-1">
                      Cart {props.cart.value.length > 0 ? "" : " is empty"}
                    </span>
                    {props.cart.value.length < 1 ? null : (
                      <span className=" bg-green-600 text-white rounded-md px-2 ml-2">
                        {props.cart.value.length}
                      </span>
                    )}
                  </div>
                </Link>
              </div>

              <div>
                <Link
                  to="/register"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Sign up
                </Link>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?{" "}
                  <Link
                    to="/login"
                    className="text-indigo-600 hover:text-indigo-500"
                  >
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

const mapStatetoProps = (state) => state;

export default connect(mapStatetoProps)(Header);
