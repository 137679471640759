import React from "react";
import Layout from "../common/Layout";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const OrderSuccess = () => {
  return (
    <Layout>
      <div className="w-5/6 md:w-1/2 mx-auto text-center mt-12">
        <CheckCircleIcon className=" w-20 h-20 stroke-green-700 mx-auto" />
        <p className="text-lg font-semibold text-green-700">
          Success your order has been placed.
        </p>
        <p>
          Your orderid :{" "}
          <Link to="/dashboard">
            <b>#2313313</b>
          </Link>{" "}
          will be delivered soon. <br />
          check the dashboard for updates on order status
        </p>
      </div>
    </Layout>
  );
};

export default OrderSuccess;
