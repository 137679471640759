import { createSlice } from "@reduxjs/toolkit";

export const cart = createSlice({
  name: "cart",
  initialState: {
    value: [],
  },
  reducers: {
    updateCart: (state, action) => {
      
      let found = false;
      const copiedCartValues = JSON.parse(JSON.stringify(state.value));
      for (let i = 0; i < copiedCartValues.length; i++) {
        if (copiedCartValues[i].id === action.payload.id) {
          found = true;
          break;
        }
      }

      if (found) {
        for (let i = 0; i < copiedCartValues.length; i++) {
          if (copiedCartValues[i].id === action.payload.id) {
            if (action.payload.quantity === 0) {
              copiedCartValues.splice(i, 1);
              state.value = [...copiedCartValues];
              break;
            } else {
              copiedCartValues[i] = action.payload;
              state.value = [...copiedCartValues];
              break;
            }
          }
        }
      } else {
        state.value = [...copiedCartValues, action.payload];
      }
    },
    emptyCart: (state) => { 
       state.value=[]
    }
  },
});

export const { updateCart,emptyCart } = cart.actions;
export default cart.reducer;
