import React, { useState } from 'react';
import { db } from '../../../firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Cookies from "universal-cookie";
import { useNavigate } from 'react-router-dom';

const PartnerLogin = () => {
    const [mobile, setMobile] = useState('');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('')
    let history = useNavigate();
    const handleSubmit = (e) => { 
        e.preventDefault();
        setLoading(true);
       
        
        const partnerRef = query(collection(db, "delpartners"), where("mobile", '==', mobile));
        getDocs(partnerRef)
            .then((res) => { 
                const list = res.docs.map((doc) => ({
                    doc: doc.data(),
                    id: doc.id,
                }));

                if (list.length < 1) {
                    setStatus('error')
                    setLoading(false)
                }
                else { 
                    let cookie = new Cookies();
                    cookie.set('partner', list[0].id);
                    alert('ok');
                    setLoading(false);
                    history('/partnerorders')
                }
        }).catch((err) => { 
            console.log(err)
        })

    }
    return (
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 mt-8">
            <div className=' flex w-full justify-center'>
                <div className='border p-4  mt-4'>
                {status === 'error' ? <p className='bg-red-500 text-white p-2 mb-4'>User details not found.</p> : ''}
                <h3>
                    <span className='text-xl font-semibold'>Hi Delivery Partner,</span>
                    <span className='block'>please enter your mobile no to continue</span></h3>
                <form className='mt-4' onSubmit={handleSubmit}>
                        <input
                            type="number"
                            className='border p-2 rounded w-full'
                            required
                            onChange={(e) => { setMobile(e.target.value)}}
                        />
                        <button
                            type="submit"
                            className='block mt-4 bg-indigo-700 text-white py-2 px-5 hover:bg-indigo-500 rounded'>
                            {loading?'Please wait..':'Verify' }
                        </button>

                        
                      
                </form>
               </div>
            </div>
        </div>
    )
}

export default PartnerLogin