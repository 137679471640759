import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../common/Layout";
import { useDispatch } from "react-redux";
import { updatePage } from "../../features/activePage";
import { db } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { connect } from "react-redux";
const UserDashboard = (props) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  dispatch(updatePage("dashboard"));

  useEffect(() => {
    const getOrders = () => {
      let uid = props.authentication.value.uid;
      let orderRef = collection(db, "orders");

      getDocs(orderRef)
        .then((res) => {
          const list = res.docs.map((doc) => ({
            doc: doc.data(),
            id: doc.id,
          }));

          let cleanList = list.filter((item) => item.doc.user === uid);
          setOrders(cleanList);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    };
    getOrders();
  }, [props.authentication]);

  if (loading) {
    return (
      <Layout>
        <p className="">loading please wait..</p>
      </Layout>
    );
  }
  return (
    <Layout>
      <section className="container mx-auto p-6 font-mono">
        <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
          <div className="w-full overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
                  <th className="px-4 py-3">Order #</th>
                  <th className="px-4 py-3">Order Date</th>
                  <th className="px-4 py-3">Order Price</th>
                  <th className="px-4 py-3">Status</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {orders.map((order, i) => {
                  return (
                    <tr className="text-gray-700">
                      <td className="px-4 py-3 border">
                        <div className="flex items-center text-sm">
                          <div>
                           
                            <Link
                              to={`/orderdetails/${order.id}`}
                              className=" text-indigo-700 hover:underline"
                            >
                              {order.doc.orderID}
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td className="px-4 py-3  border">
                        {order.doc.orderDate}
                      </td>
                      <td className="px-4 py-3  border">
                        {order.doc.total}
                      </td>
                      <td className="px-4 py-3 text-sm border">
                        {" "}
                        <span className="bg-orange-500 px-3 py-1 rounded-md text-white text-sm">
                          {order.doc.status}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(UserDashboard);
