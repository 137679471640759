/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  addDoc,
  doc,
  collection,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../firebase-config";
import { images } from "../../common/utils";

export default function ProductModal(props) {
 
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [price, setPrice] = useState("");
  const [unit, setUnit] = useState("KG");
  const [vendor, setVendor] = useState("");
  const [vendors, setVendors] = useState([]);
  const [retailPrice, setRetailPrice] = useState(0);
  const [minQuantity,setMinQuantity] = useState(1)


  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const cancelButtonRef = useRef(null);

  useEffect(() => {
  
    if (props.data !== null) {
      let { title, description, unit,vendor,image,price,minQuantity,retailPrice } = props.data.doc;
      setTitle(title);
      setUnit(unit);
      setDescription(description);
      setVendor(vendor);
      setImage(image);
      setPrice(price);
      setMinQuantity(minQuantity);
      setRetailPrice(retailPrice)
    }

    const getVendors = () => {
      const vendorRef = collection(db, "vendors");
      getDocs(vendorRef)
        .then((res) => {
          const list = res.docs.map((doc) => ({
            doc: doc.data(),
            id: doc.id,
          }));
          setVendors(list);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getVendors();
  }, [props.data]);

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);

    if (props.data === null) {
      const productRef = collection(db, "products");
      addDoc(productRef, { title,description,image,price,unit,vendor,minQuantity,retailPrice })
        .then((res) => {
          if (res.id !== "") {
            setStatus("success");
            setTimeout(() => {
              setOpen(false);
            }, 3000);
          } else {
            setStatus("error");
          }
          setLoading(false);
        })
        .catch((err) => {
          setStatus("error");
          setLoading(false);
          console.log(err);
        });
    } else {
      const productRef = doc(db, "products", props.data.id);
      updateDoc(productRef, {title,description,image,price,unit,vendor,minQuantity,retailPrice })
        .then((res) => {
          setStatus("success");
          setLoading(false);
        })
        .catch((err) => {
          setStatus("error");
          setLoading(false);
          console.log(err);
        });
    }
  };

  const resetState = () => {
    props.resetActiveProd();
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={resetState}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <form onSubmit={handleSubmit}>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      {status === "success" ? (
                        <div className="bg-green-500 text-white p-2 rounded mb-4">
                          Product added/updated successfully!
                        </div>
                      ) : null}
                      {status === "error" ? (
                        <div className="bg-red-500 text-white p-2 rounded mb-4">
                          Sorry there was an error while adding product
                        </div>
                      ) : null}
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Add Product
                      </Dialog.Title>

                      <div className="mt-2 border-t pt-4">
                        <label>Title</label>
                        <input
                          type="text"
                          className="border block p-2 rounded w-full"
                          placeholder="Ex : Potato"
                          defaultValue={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                      <div className="mt-2 pt-4">
                        <label>Description</label>
                        <textarea
                          type="text"
                          className="border block p-2 rounded w-full"
                          onChange={(e) => setDescription(e.target.value)}
                          defaultValue={description}
                        ></textarea>
                      </div>

                      <div className="mt-2 pt-4">
                        <label>Vendor</label>
                        <select
                          className="block p-2 w-full border"
                          value={vendor}
                          onChange={(e) => {
                            setVendor(e.target.value);
                          }}
                        >
                          <option value=""></option>
                          {vendors.map((vendor, i) => {
                            return (
                              <option value={vendor.id} key={i}>
                                {vendor.doc.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="mt-2 pt-4">
                        <label>Product image</label>
                        <select className="block p-2 w-full border" value={image} onChange={(e) => { setImage(e.target.value)}}>
                          <option value=""></option>
                          {
                            images.map((product, i) => { 
                              return (<option value={product.link} key={i}>{product.name}</option>)
                            })
                          }
                        </select>
                      </div>

                      <div className="mt-2 pt-4  grid grid-cols-2 gap-2">
                        <div>
                        <label>Price</label>
                        <input
                          type="text"
                          className="border block p-2 rounded w-full"
                          placeholder="Ex : 120"
                          defaultValue={price}
                            onChange={(e) => {
                             
                            setRetailPrice((Number(e.target.value)*0.05)+(Number(e.target.value)))
                            setPrice(e.target.value);
                          }}
                        />
                        </div>
                        <div>
                        <label>Retail Price</label>
                        <input
                          type="text"
                          className="border block p-2 rounded w-full"
                          placeholder="Ex : 5%"
                           
                            value={retailPrice}
                          onChange={(e) => {
                            setRetailPrice(e.target.value);
                          }}
                        />
                          </div>
                      </div>

                      <div className="mt-2 pt-4 grid grid-cols-2 gap-2">
                        <div >
                        <label>Minimum quantity</label>
                          <input type='text'
                            onChange={(e) => { setMinQuantity(e.target.value) }}
                            className="block p-2 w-full border" placeholder="eg: 1 or 0.5"
                            value={minQuantity} />
                        </div>
                        <div >
                        <label>Unit</label>
                          <select className="block p-2 w-full border" value={unit}
                            onChange={(e) => { setUnit(e.target.value) }}>
                        <option value=""></option>
                          <option value="KG">KG</option>
                          <option value="PIECE">Piece</option>
                        </select>
                         </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    
                      disabled={loading ? true : false}
                    >
                      {loading ? "Please wait.." : "Submit"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        resetState();
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
