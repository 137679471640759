import React, { useState } from "react";
import ProductsList from "./ProductsList";
import ProductModal from "./ProductModal";

const ProductInventory = () => {
  const [show, setShow] = useState(false);
  const [selectedProd,setSelectedProd] = useState(null)
  const resetActiveProd = () => {
    setShow(false);
   
  };
  const editHandler = (data) => {
    setShow(true)
    setSelectedProd(data);
   }

  return (
    <div>
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">Products list</h3>
        <button
          className="bg-indigo-700 text-white px-2 py-1 rounded hover:bg-indigo-900"
          onClick={() => {
            setSelectedProd(null)
            setShow(true);
          }}
        >
          + Add Product
        </button>
      </div>

      <ProductsList editHandler={editHandler} />
      {show ? <ProductModal data={selectedProd} resetActiveProd={resetActiveProd} /> : null}
    </div>
  );
};

export default ProductInventory;
