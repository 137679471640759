import React, { useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import loader from "../../../assets/loader.gif"

const AddProductVoice = (props) => {
  const {
    transcript,
    listening,
    finalTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (finalTranscript !== "") {
      props.setPropData(finalTranscript);
    }
  }, [finalTranscript]);


  const RenderTitle = (prop) => { 

    useEffect(() => { 
           SpeechRecognition.startListening()
    },[])

    // if (prop.type === "title") {
    //   return (
    //     <p className="text-center text-2xl mb-3">What's the product name?</p>
    //   )
    // }

     if (prop.type === 'wprice') { 
      return (
        <p className="text-center text-2xl mb-3">What's the wholesale price?</p>
      )
    }
    // else if (prop.type === 'rprice') { 
    //   return (
    //     <p className="text-center text-2xl mb-3">What's the retail price?</p>
    //   )
    // }

   
  }

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div>
      <RenderTitle type={props.type} />
       {listening ? <div className="text-center"><img src={loader} alt="loader" className="w-24 mx-auto" /> </div>: null}
     
      <p className="text-center font-bold uppercase">{transcript}</p>
    </div>
  );
};

export default AddProductVoice;
