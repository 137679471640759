import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import AssignDelivery from "./AssignDelivery";
import { findsts } from "../../common/common";

const OrderList = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [show, setShow] = useState(false);
  const[activeOrder,setActiveOrder] = useState(null)


  useEffect(() => {
    const getOrders = () => {
      const orderRef = collection(db, "orders");
      getDocs(orderRef)
        .then((res) => {
          const list = res.docs.map((doc) => ({
            doc: doc.data(),
            id: doc.id,
          }));
         
          setOrders(list);
          setLoading(false);
          
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    };

    getOrders();
  }, []);

  const setOrder = (orderId) => {

    setShow(true);
    setActiveOrder(orderId)
   }
  
  const getTotal = (items) => { 
    let total = 0;

    for (let i = 0; i < items.length; i++) { 

      let qty = Number(items[i].quantity);
      let price = Number(items[i].doc.price);
      total += (price * qty);

    }
    return total
  }

  const resetState = () => { 
    setShow(false);
    const getOrders = () => {
     // let structuredOrders = [];
      const orderRef = collection(db, "orders");
      getDocs(orderRef)
        .then((res) => {
          const list = res.docs.map((doc) => ({
            doc: doc.data(),
            id: doc.id,
          }));

         
         
          setOrders(list);
          setLoading(false);
          
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    };

    getOrders();
  }

  if (loading) { 
    return <p>Please wait...</p>
  }
  return (
    <section className="container mx-auto p-4 font-mono">
      <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
        <div className="w-full overflow-x-auto">
    <table className="w-full">
      <thead>
      <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase  ">
        <th className=" text-left px-4 py-3">Order #</th>
        <th className=" text-left px-4 py-3">Order Date</th>
        <th className=" text-left px-4 py-3">Order Price</th>
          <th className=" text-center px-4 py-3">Status</th>
          <th className="text-center px-4 py-3">Assign partner</th>
        </tr>
      </thead>
      <tbody className="bg-white">
      {
        orders.map((order) => { 

          return(<tr  key={order.id}>
          <td className="px-4 py-3 border">
            <Link to={`/orderdetails/${order.id}`} className=" text-indigo-700 hover:underline">
                { order.doc.orderID}
            </Link>
          </td>
            <td className="px-4 py-3 border">{ order.doc.orderDate}</td>
            <td className="px-4 py-3 border"><b>INR { getTotal(order.doc.items)}.00</b></td>
          <td className="px-4 py-3 border text-center">
              <span className={`px-3 py-1 rounded-md text-sm ${findsts(order.doc.status)}`}>
                { order.doc.status}
            </span>
            </td>
            <td className="px-4 py-3 border text-center">
              <button
                className="bg-indigo-500 text-white py-1 px-3 rounded hover:bg-indigo-700"
                onClick={() => { setOrder(order.id)}}
              >Assign</button>
            </td>
        </tr>)
        })
        }
        </tbody>
      </table>
      {
        show ? <AssignDelivery resetState={resetState} activeOrder={activeOrder} />:null
          }
          </div>
       </div>
      </section>
  );
};

export default OrderList;
