import React from "react";
import Header from "./Header";
const Layout = (props) => {
  return (
    <div>
      <Header />
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 mt-8">
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
