import React from "react";

const CartItem = ({ item }) => {

  return (
    <div className="border-b">
      <div className="p-4 flex h-24 justify-between items-center">
        <div
          className=" w-32 h-20"
          style={{
            background: `url(${item.doc.image}) no-repeat`,
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="font-semibold w-2/5">
          {item.doc.title}
          <small className="block font-normal hidden md:block">{item.doc.description}</small>
        </div>
        <div className=" w-2/5 text-right">
          <p className="text-sm">
           
            {item.minUnit} {item.doc.unit} x {item.quantity}
          </p>
          <p className=" font-medium">
            INR {((Number(item.doc.retailPrice)*(item.minUnit)) * Number(item.quantity)).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
