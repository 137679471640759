import React, {useState,useEffect} from 'react';
import { db } from '../../../firebase-config';
import { collection,getDocs,query, where  } from 'firebase/firestore';
import Cookies from 'universal-cookie';

const VendorOrderList = () => {

    const [orders, setOrders] = useState([]);

    useEffect(() => { 
        

        const getOrders = () => { 
            
            let cookies = new Cookies()
            let vendorID = cookies.get('vendor')

            let vendorOrderedItems = [];
           
            const q = query(collection(db, "orders"), where("vendors", 'array-contains', vendorID));
            getDocs(q)
                .then((res) => {
                    const list = res.docs.map((doc) => ({ doc: doc.data(), id: doc.id }));
                    
                   
                    for (let i = 0; i < list.length; i++) { 

                        let items = list[i].doc.items;
                        let orderID = list[i].doc.orderID
                        for (let j = 0; j < items.length; j++) { 
                            if (items[j].doc.vendor === vendorID) {
                                items[j]["orderID"] = orderID
                                vendorOrderedItems.push(items[j])
                             }
                        }
                    }
                  
                    setOrders(vendorOrderedItems)
                })
                .catch((err) => { console.log(err) })
            

        }
        getOrders();

    },[])
  return (
    <section className="container mx-auto p-6 font-mono">
    <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
      <div className="w-full overflow-x-auto">
              <table className='w-full'>
                  <thead>
                  <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600">
                          <th className="px-4 py-3">Product</th>
                          <th className="px-4 py-3">Quantity</th>
                          <th className="px-4 py-3">Order ID</th>
                          
                      </tr>
                  </thead>
                  <tbody className='bg-white'>
                      {
                          orders.map((order,i) => { 
                              return (
                                <tr key={i}>
                                      <td className='px-4 py-3 border capitalize'>{ order.doc.title}</td>
                                      <td className='px-4 py-3 border'>{order.quantity}{order.doc.unit}</td>
                                <td className='px-4 py-3 border'>#{order.orderID}</td>
                                
                            </tr>
                              )
                          })
                      }
                    
                      
                    
                  </tbody>
              </table>
          </div>

         
          </div>
          </section>
  )
}

export default VendorOrderList