import { createSlice } from "@reduxjs/toolkit";

export const authenticate = createSlice({
  name: "authentication",
  initialState: {
    value: {},
  },
  reducers: {
    login: (state, { payload}) => {
      state.value = {...payload};
    },
    logout: (state) => {
      state.value = {};
    },
  },
});

export const { login, logout } = authenticate.actions;

export default authenticate.reducer;
