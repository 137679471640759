import { configureStore } from "@reduxjs/toolkit";
import authentication from "../features/authentication";
import cart from "../features/cart";
import activePage from "../features/activePage";

export default configureStore({
  reducer: {
    authentication: authentication,
    cart: cart,
    activePage: activePage,
  },
});
