import React, { useEffect,useState } from 'react';
import { db } from '../../../firebase-config';
import { collection, getDocs, where, query,doc, updateDoc } from 'firebase/firestore';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../../../assets/logo.svg'
const PartnerOrders = () => {
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [updating, setUpdating] = useState(false);

    let history = useNavigate();
    useEffect(() => {
        let cookies = new Cookies();
        let partner = cookies.get('partner');
        
        if (partner === undefined || partner === '') {
            history('/')
        }
        const getOrders = () => {
            const orderRef = query(collection(db, 'orders'), where('dpartner', '==', partner));
            getDocs(orderRef)
                .then((res) => {
                    const list = res.docs.map((doc) => ({ id: doc.id, doc: doc.data() }));
                    setOrders(list)
                    setLoading(false);
                
                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err)
                })
        }
        getOrders()
    }, []);

    const getOrders = () => {
        let cookies = new Cookies();
        let partner = cookies.get('partner');
        
        const orderRef = query(collection(db, 'orders'), where('dpartner', '==', partner));
        getDocs(orderRef)
            .then((res) => {
                const list = res.docs.map((doc) => ({ id: doc.id, doc: doc.data() }));
                setOrders(list)
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    const updateOrder = (orderid, status) => {
        if (status !== '') {
            setUpdating(true)
            const orderRef = doc(db, "orders", orderid);
            updateDoc(orderRef, { status: status })
                .then((res) => {  setUpdating(false);getOrders() })
                .catch((err) => { console.log(err);setUpdating(false) })
        }
    }
    
    const getTotal = (items) => { 
        let total = 0;
    
        for (let i = 0; i < items.length; i++) { 
    
          let qty = Number(items[i].quantity);
          let price = Number(items[i].doc.price);
          total += (price * qty);
    
        }
        return total
      }

    if (loading) { 
        return <p>Please wait..</p>
    }
  return (
      <>
        <div className="border-b border-gray-200">
    <header className='mx-auto max-w-7xl sm:px-6 lg:px-8 h-43  py-2 flex justify-between items-center'>
          <img src={logo} alt="logo" className="w-32 pl-2" />
          <Link to="/login" className='hover:text-indigo-500'>Logout</Link>
        </header>
        </div>
     
     
      <section className="container mx-auto p-4 ">
      <div className="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
        <div className="w-full overflow-x-auto">
            <h3 className='py-3 px-4 text-xl font-semibold bg-indigo-50'>Orders to be delivered</h3>
    <table className="w-full border border-collapse font-mono">
      <thead>
      <tr  className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase  ">
        <th className=" text-left px-4 py-3">Order #</th>
        
        <th className=" text-left px-4 py-3">Order Price</th>
          <th className="text-center  px-4 py-3">Status</th>
          <th className="text-center px-4 py-3">Change Status</th>
        </tr>
      </thead>
      <tbody>
      {
        orders.map((order) => { 

            return (<tr  key={order.id} data={order.id}>
          <td className="px-4 py-3 border">
            <Link to={`/orderdetails/${order.id}`} className=" text-indigo-700 hover:underline">
                { order.doc.orderID}
            </Link>
          </td>
           
            <td className="px-4 py-3 border ">INR { getTotal(order.doc.items)}.00</td>
          <td className="px-4 py-3 border text-center">
            <span className="bg-orange-500 px-3 py-1 rounded-md text-white text-sm">
                { order.doc.status}
            </span>
              </td>
              <td className='text-center px-4 py-3 border'>
                  <select className='border p-2 rounded-lg' onChange={(e) =>{ updateOrder(order.id,e.target.value) }}>
                        <option value="">Update Status</option>
                        <option value="Delivered">Delivered</option>
                      <option value="Customer Unavailable">Customer Unavailable</option>
                      <option value="Delayed">Delayed</option>
                  </select>
                  { updating ?  <p>updating...</p>:null}
                  
              </td>
           
        </tr>)
        })
        }
        </tbody>
            </table>
            </div></div></section>
  
      </>
  )
}

export default PartnerOrders