import React from "react";
import Layout from "../common/Layout";
import CartItem from "../common/CartItem";
import { db } from "../../firebase-config";
import { doc,getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import UserMap from "../common/UserMap";
import { findsts } from "../common/common";

const OrderDetails = (props) => {

  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState([])
  const [orderID,setOrderID] = useState('')
  let { id } =useParams();
  useEffect(() => {
   
    const getOrder = () => {
     
      let orderRef = doc(db, 'orders', id);
      getDoc(orderRef).then((res) => {
       
        let data = res.data();
          console.log(data)
        setLoading(false)
        setOrderID(id)
        setOrder(data)
      }).catch((err) => { console.log(err); setLoading(false); })

     }
   getOrder()
   },[])

  if (loading) { 
    return(<Layout><p>please wait..</p></Layout>)
  }
  return (
    <Layout>
      <div>
        <p className="text-lg">
          OrderDetails for order <b>#{order.orderID}</b>
        </p>

        <div className="border mt-4 rounded-lg">
          {order.items.map((item, i) => {
            return <CartItem key={i} item={item} />;
          })}

          <div className="flex  justify-between items-center">
            <div className="border-r p-4 flex-1">
              <p>
                Order status :{" "}
                <span className={`px-3 py-1 rounded text-sm ${findsts(order.status)}`}>
                  {order.status}
                </span>
              </p>
              <p className="text-sm border-t mt-4 pt-4 mb-4">
                <span className="font-semibold">Delivery location:</span>
      
              
                <br />
                <span>
                  { order.address}
                </span>
              </p>

              <UserMap data={ order.coords} />
            </div>
            <div className="p-4 w-1/3 flex flex-col justify-center items-center">
              <span>Order total</span>
              <p className="text-xl font-semibold ">{order.total}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OrderDetails;
