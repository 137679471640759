import React, { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../firebase-config';

const Invoices = () => {

    const [invoices, setInvoices] = useState([]);
    const [vendors, setVendors] = useState([]);
    const totalPrice = (list) => {
        let total = 0;  
        for (let i = 0; i < list.length; i++) { 
            let price = Number(list[i].item.doc.retailPrice);
            let quantity = Number(list[i].item.quantity)
             total += (price*quantity)
        }
        return total.toFixed(2);
    }

    const price = (quantity, retailPrice) => { 
        return (Number(quantity)*Number(retailPrice)).toFixed(2) 
    }
    

    useEffect(() => {

        const getOrders = () => {
            const orderRef = collection(db, 'orders');
           

            getDocs(orderRef)
                .then((res) => {
                    const list = res.docs.map((doc) => ({ doc: doc.data(), id: doc.id }));
                  
                    const structuredOrders = {};
                    for (let i = 0; i < list.length; i++) {
                        let vendorItems = list[i].doc.items;
                        for (let j = 0; j < vendorItems.length; j++) {
                            let vendor = vendorItems[j].doc.vendor;
                            let item = vendorItems[j]
                           
                            if (!structuredOrders[vendor]) {
                                structuredOrders[vendor] = [{ item: item, orderID: list[i].doc.orderID }]
                            }
                            else {
                                structuredOrders[vendor].push({ item: item, orderID: list[i].doc.orderID })
                            }
                        }

                    }
                    let setInvoiceVal = [];
                    let keys = Object.keys(structuredOrders);
                    for (let i = 0; i < keys.length; i++) {
                        setInvoiceVal.push({ vendor: keys[i], items: structuredOrders[keys[i]] })
                    }
                    setInvoices(setInvoiceVal)
                })
                .catch((err) => {
                    console.log(err)
                });
        }

        const getVendors = () => {
            const vendorRef = collection(db, 'vendors')
            getDocs(vendorRef).then((res) => {
                const list = res.docs.map((doc) => ({ doc: doc.data(), id: doc.id }));
                //console.log(list);
                setVendors(list)
            })
        }
        getOrders();
        getVendors();
    }, []);

    const findVendorName = (id) => {
        let vendorName = '';


        for (let i = 0; i < vendors.length; i++) { 
            if (id === vendors[i]["id"]) { 
                vendorName = vendors[i]["doc"]["name"];
                break;
            }
        }

        return vendorName
    }
    return (
        <div> <h2 className="text-xl font-semibold">Invoices list</h2>

            {
                invoices.map((details, i) => {

                    return (
                        <div className='border rounded mt-4' key={i}>
                            <div className='flex justify-between p-4 border-b bg-gray-600 text-white'>
                                <p className='font-semibold'>{findVendorName(details.vendor)}</p>
                                <p>Total Amount : INR {totalPrice(details.items)}</p>
                            </div>
                            <table className='w-full'>
                                <thead>
                                    <tr className='bg-gray-100'>
                                        <th className='text-left p-4 font-normal text-sm'>Product</th>
                                        <th className='text-left p-4 font-normal text-sm'>Quantity</th>
                                        <th className='text-left p-4 font-normal text-sm'>Order ID</th>
                                        <th className='text-left p-4 font-normal text-sm'>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        details.items.map((itemObj, j) => {
                                            return (<tr className='border-b' key={`${itemObj.item.id}-${details.vendor}-${i + j}`}>
                                                <td className='p-4'>{itemObj.item.doc.title}</td>
                                                <td className='p-4'>{itemObj.item.quantity}{itemObj.item.doc.unit}</td>
                                                <td className='p-4'>{itemObj.orderID}</td>
                                                <td className='p-4'>
                                                    {itemObj.item.quantity}x{itemObj.item.doc.retailPrice} 
                                                    <span className='font-semibold'>  INR {price(itemObj.item.doc.retailPrice, itemObj.item.quantity)}
                                                    </span>
                                                </td>
                                            </tr>)
                                        })
                                    }


                                </tbody>
                            </table>
                        </div>
                    )
                })
            }



        </div>
    )
}

export default Invoices